var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-md-12 col-lg-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm",
                        attrs: { title: "기본정보" },
                      },
                      [
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-select", {
                                attrs: {
                                  required: "",
                                  type: "edit",
                                  codeGroupCd: "HHM_TYPE_CD",
                                  itemText: "codeName",
                                  itemValue: "code",
                                  name: "machineryClassification",
                                  label: "기계분류",
                                },
                                model: {
                                  value: _vm.equipInfo.machineryClassification,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.equipInfo,
                                      "machineryClassification",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "equipInfo.machineryClassification",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  required: "",
                                  disabled: _vm.disabled,
                                  editable: _vm.editable,
                                  label: "기계명",
                                  name: "machineryName",
                                },
                                model: {
                                  value: _vm.equipInfo.machineryName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.equipInfo,
                                      "machineryName",
                                      $$v
                                    )
                                  },
                                  expression: "equipInfo.machineryName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  afterIcon: [
                                    {
                                      name: "search",
                                      click: true,
                                      callbackName: "add",
                                    },
                                    {
                                      name: "close",
                                      click: true,
                                      callbackName: "remove",
                                    },
                                  ],
                                  editable: _vm.editable,
                                  label: "기계관리번호",
                                  disabled: _vm.disabled,
                                  close: false,
                                  name: "equipmentNo",
                                },
                                on: { add: _vm.add, remove: _vm.remove },
                                model: {
                                  value: _vm.equipInfo.equipmentNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.equipInfo, "equipmentNo", $$v)
                                  },
                                  expression: "equipInfo.equipmentNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable,
                                  disabled: true,
                                  label: "item No",
                                  name: "itemNo",
                                },
                                model: {
                                  value: _vm.equipInfo.itemNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.equipInfo, "itemNo", $$v)
                                  },
                                  expression: "equipInfo.itemNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-process", {
                                attrs: {
                                  editable: _vm.editable,
                                  label: "단위공정",
                                  multiple: "multiple",
                                  name: "subProcessCd",
                                },
                                model: {
                                  value: _vm.equipInfo.subProcessCd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.equipInfo, "subProcessCd", $$v)
                                  },
                                  expression: "equipInfo.subProcessCd",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-dept-multi", {
                                attrs: {
                                  type: "edit",
                                  required: "",
                                  disabled: _vm.disabled,
                                  label: "담당부서",
                                  name: "departmentDeptCd",
                                },
                                model: {
                                  value: _vm.equipInfo.departmentDeptCd,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.equipInfo,
                                      "departmentDeptCd",
                                      $$v
                                    )
                                  },
                                  expression: "equipInfo.departmentDeptCd",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-plant", {
                                attrs: {
                                  required: "",
                                  editable: _vm.editable,
                                  type: "edit",
                                  name: "plantCd",
                                },
                                model: {
                                  value: _vm.equipInfo.plantCd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.equipInfo, "plantCd", $$v)
                                  },
                                  expression: "equipInfo.plantCd",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                            },
                            [
                              _c("c-field", {
                                attrs: {
                                  editable: _vm.editable,
                                  label: "신청인",
                                  type: "user",
                                  name: "departmentUserId",
                                },
                                model: {
                                  value: _vm.equipInfo.departmentUserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.equipInfo,
                                      "departmentUserId",
                                      $$v
                                    )
                                  },
                                  expression: "equipInfo.departmentUserId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                            },
                            [
                              _c("c-checkbox", {
                                attrs: {
                                  editable: _vm.editable,
                                  isFlag: true,
                                  label: "사용여부",
                                  name: "useFlag",
                                },
                                model: {
                                  value: _vm.equipInfo.useFlag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.equipInfo, "useFlag", $$v)
                                  },
                                  expression: "equipInfo.useFlag",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm",
                        attrs: { title: "설치상태 정보" },
                      },
                      [
                        _c(
                          "template",
                          { slot: "card-button" },
                          [
                            _c(
                              "q-btn-group",
                              { attrs: { outline: "" } },
                              [
                                _vm.editable
                                  ? _c("c-btn", {
                                      attrs: {
                                        url: _vm.saveUrl,
                                        isSubmit: _vm.isSave,
                                        param: _vm.equipInfo,
                                        mappingType: _vm.saveType,
                                        label: "저장",
                                        icon: "save",
                                      },
                                      on: {
                                        beforeAction: _vm.saveInfo,
                                        btnCallback: _vm.saveCallback,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                            },
                            [
                              _c("c-datepicker", {
                                attrs: {
                                  type: "date",
                                  editable: _vm.editable,
                                  label: "설치일",
                                  name: "emplacementMonth",
                                },
                                model: {
                                  value: _vm.equipInfo.emplacementMonth,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.equipInfo,
                                      "emplacementMonth",
                                      $$v
                                    )
                                  },
                                  expression: "equipInfo.emplacementMonth",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                            },
                            [
                              _c("c-datepicker", {
                                attrs: {
                                  editable: _vm.editable,
                                  type: "date",
                                  label: "철거일",
                                  name: "demolitionMonth",
                                },
                                model: {
                                  value: _vm.equipInfo.demolitionMonth,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.equipInfo,
                                      "demolitionMonth",
                                      $$v
                                    )
                                  },
                                  expression: "equipInfo.demolitionMonth",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable,
                                  label: "제조사",
                                  name: "manufacturer",
                                },
                                model: {
                                  value: _vm.equipInfo.manufacturer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.equipInfo, "manufacturer", $$v)
                                  },
                                  expression: "equipInfo.manufacturer",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                            },
                            [
                              _c("c-datepicker", {
                                attrs: {
                                  editable: _vm.editable,
                                  type: "date",
                                  label: "제조일",
                                  name: "manufactDate",
                                },
                                model: {
                                  value: _vm.equipInfo.manufactDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.equipInfo, "manufactDate", $$v)
                                  },
                                  expression: "equipInfo.manufactDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  suffix: "ton",
                                  type: "number",
                                  editable: _vm.editable,
                                  label: "용량",
                                  name: "volume",
                                },
                                model: {
                                  value: _vm.equipInfo.volume,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.equipInfo, "volume", $$v)
                                  },
                                  expression: "equipInfo.volume",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable,
                                  label: "설치장소",
                                  name: "emplacementPlace",
                                },
                                model: {
                                  value: _vm.equipInfo.emplacementPlace,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.equipInfo,
                                      "emplacementPlace",
                                      $$v
                                    )
                                  },
                                  expression: "equipInfo.emplacementPlace",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable,
                                  label: "EWR NO.",
                                  name: "ewrNo",
                                },
                                model: {
                                  value: _vm.equipInfo.ewrNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.equipInfo, "ewrNo", $$v)
                                  },
                                  expression: "equipInfo.ewrNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable,
                                  label: "발생가능재해형태",
                                  name: "possibleDisaster",
                                },
                                model: {
                                  value: _vm.equipInfo.possibleDisaster,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.equipInfo,
                                      "possibleDisaster",
                                      $$v
                                    )
                                  },
                                  expression: "equipInfo.possibleDisaster",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable,
                                  label: "방호장치",
                                  name: "protectDevice",
                                },
                                model: {
                                  value: _vm.equipInfo.protectDevice,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.equipInfo,
                                      "protectDevice",
                                      $$v
                                    )
                                  },
                                  expression: "equipInfo.protectDevice",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable,
                                  label: "비고",
                                  name: "remark",
                                },
                                model: {
                                  value: _vm.equipInfo.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.equipInfo, "remark", $$v)
                                  },
                                  expression: "equipInfo.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "안전검사정보" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-select", {
                          attrs: {
                            type: "edit",
                            codeGroupCd: "HAZARD_GRADE_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "gradeCd",
                            label: "등급",
                          },
                          model: {
                            value: _vm.equipInfo.gradeCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipInfo, "gradeCd", $$v)
                            },
                            expression: "equipInfo.gradeCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-select", {
                          attrs: {
                            required: "",
                            comboItems: _vm.targetCdItems,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            editable: _vm.editable,
                            label: "검사상태 여부",
                            name: "inspectionSubjectFlag",
                          },
                          on: { datachange: _vm.datachange },
                          model: {
                            value: _vm.equipInfo.inspectionSubjectFlag,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.equipInfo,
                                "inspectionSubjectFlag",
                                $$v
                              )
                            },
                            expression: "equipInfo.inspectionSubjectFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-select", {
                          attrs: {
                            type: "edit",
                            disabled: !(
                              _vm.equipInfo.inspectionSubjectFlag === "Y"
                            ),
                            codeGroupCd: "MDM_CHECK_KIND_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            editable: _vm.editable,
                            label: "검사종류",
                            name: "inspectionKindCd",
                          },
                          model: {
                            value: _vm.equipInfo.inspectionKindCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipInfo, "inspectionKindCd", $$v)
                            },
                            expression: "equipInfo.inspectionKindCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: !(
                              _vm.equipInfo.inspectionSubjectFlag === "Y"
                            ),
                            type: "edit",
                            codeGroupCd: "MDM_EQUIP_CYCLE_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            label: "검사주기",
                            name: "inspectionCycle",
                          },
                          model: {
                            value: _vm.equipInfo.inspectionCycle,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipInfo, "inspectionCycle", $$v)
                            },
                            expression: "equipInfo.inspectionCycle",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            editable: _vm.editable,
                            label: "검사증교부일",
                            name: "inspCertificateIssuanceDate",
                          },
                          model: {
                            value: _vm.equipInfo.inspCertificateIssuanceDate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.equipInfo,
                                "inspCertificateIssuanceDate",
                                $$v
                              )
                            },
                            expression: "equipInfo.inspCertificateIssuanceDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            editable: _vm.editable,
                            range: true,
                            label: "유효기간",
                            name: "validPeriod",
                          },
                          model: {
                            value: _vm.validPeriod,
                            callback: function ($$v) {
                              _vm.validPeriod = $$v
                            },
                            expression: "validPeriod",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "합격번호",
                            name: "passNumber",
                          },
                          model: {
                            value: _vm.equipInfo.passNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipInfo, "passNumber", $$v)
                            },
                            expression: "equipInfo.passNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-multi-select", {
                          attrs: {
                            editable: _vm.editable,
                            isArray: false,
                            codeGroupCd: "REGULATION_BILL_API_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            label: "관련 법규",
                            name: "relatedLaws",
                            maxValues: "3",
                          },
                          model: {
                            value: _vm.equipInfo.relatedLaws,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipInfo, "relatedLaws", $$v)
                            },
                            expression: "equipInfo.relatedLaws",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "설계근거(정격하중)",
                            name: "esignBasisRatedLoad",
                          },
                          model: {
                            value: _vm.equipInfo.esignBasisRatedLoad,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.equipInfo,
                                "esignBasisRatedLoad",
                                $$v
                              )
                            },
                            expression: "equipInfo.esignBasisRatedLoad",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "설계근거(종류)",
                            name: "esignBasisKind",
                          },
                          model: {
                            value: _vm.equipInfo.esignBasisKind,
                            callback: function ($$v) {
                              _vm.$set(_vm.equipInfo, "esignBasisKind", $$v)
                            },
                            expression: "equipInfo.esignBasisKind",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            label: "검사면제사유",
                            rows: 3,
                            name: "reasonExemptionInspection",
                          },
                          model: {
                            value: _vm.equipInfo.reasonExemptionInspection,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.equipInfo,
                                "reasonExemptionInspection",
                                $$v
                              )
                            },
                            expression: "equipInfo.reasonExemptionInspection",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }